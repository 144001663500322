<template>
    <footer class="sticky-footer bg-white">
        <div class="container my-auto">
            <div class="copyright my-auto">
                        <span>
                            OBF - S.A - Capital 10.000.000 Fcfa - Abidjan-Cocody-Bonoumin, imm. CIAT - RC : CI-ABJ-03-2021-B14-00027
                            , CC : 2109932-Y
                            Regime :  Réel Simplifié, 06 BP 1044 Abidjan 06 Côte d'Ivoire - Tél : (225)27.76.300.400 / 07.07.64.64.48
                        </span>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>
