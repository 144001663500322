<!-- eslint-disable vue/valid-template-root -->
// eslint-disable-next-line vue/valid-template-root
<template>
    <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

    </nav>
</template>

<script>
export default {

}
</script>

<style>
</style>
